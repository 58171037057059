import React from 'react';
import { Card, CardHeader, Row, Col, UncontrolledTooltip, Spinner } from 'reactstrap';
import { editableCartTypes, FULLY_EDITABLE_CARDS, SERVICE_STATE } from '../../utils/constants';
import Cart from './Cart';
import moment from 'moment';
import PublishedLabel from '../../components/labels/PublishedLabel';
import { Switch } from 'antd';
import {
  PUBLISH_OR_UNPUBLISH_CART,
  SAVE_CARDS_ORDER,
  UNDECLINE_SERVICE,
} from '../../queries/vip-workflow';
import { useApolloClient } from '@apollo/client';
import { errorMsg, infoMsg, successMsg } from '../../cache/vars';
import { CARD_FORM_SCHEMA } from '../../utils/cardConfigs';
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';
import axios from 'axios';

const DEFAULT_CARD_CONFIG = [
  {
    label: '',
    name: 'description',
    defaultValue: '-',
    inputType: 'text',
    style: {
      marginTop: '20px',
    },
    alignToLeft: true,
    icon: 'fa fa-lg fa-regular fa-pen',
  },
];

const CartsContainer = ({
  packageOrderWithDetails,
  setPackageOrderWithDetails,
  setModalGroup,
  setPackageDetailsId,
  setIsModalOpened,
  vegasLimoTransferType,
  setVegasLimoTransferType,
  airportTransferType,
  setAirportTransferType,
  isCartInfoOpenedId,
  handleOpenDetails,
  handleOpenLearnMore,
  isLearnMoreOpenedId,
  setModalName,
  user,
  triggerRefresh,
}: any) => {
  const client = useApolloClient();

  const [reservationSuccess, setReservationSuccess] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  
  const getJwtFromCookies = () => {
    const jwtCookie = document.cookie.split('; ').find(row => row.startsWith('jwt='));
    return jwtCookie ? jwtCookie.split('=')[1] : null;
  };
  
  async function handleMakeReservation() {
    if (window.confirm('Are you sure you want to make a reservation? Please refrain from refreshing the page or editing information on the invoice. Thank you for your patience.')) {
      setIsLoading(true);
      const jwtToken = getJwtFromCookies();
      if (!jwtToken) {
        errorMsg('You are not authenticated. Please login.');
        setIsLoading(false);
        return;
      }

      const endpoint = `${
        process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'development' ? 'http' : 'https'
      }://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/graphql`;

      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${jwtToken}`,
      };

      const graphqlQuery = {
        operationName: "GetReservationByInvoiceId",
        query: `
          query GetReservationByInvoiceId($invoiceId: Float!, $user: String!) {
            getReservationByInvoiceId(invoiceId: $invoiceId, user: $user) {
              success
              packagesTotal
              successfulReservations
              failedReservations
            }
          }
        `,
        variables: {
          invoiceId: packageOrderWithDetails?.invoiceId,
          user: user,
        }
      };

      try {
        const response = await axios({
          url: endpoint,
          method: 'post',
          headers: headers,
          data: graphqlQuery,
          timeout: 300000
        });

        const data = response.data;

        if (data.errors) {
          console.error('GraphQL Errors:', data.errors);
          errorMsg('Service is currently unavailable. Please try again later.');
        } else if (data.data?.getReservationByInvoiceId?.successfulReservations) {
          successMsg('Reserved successfully.');
          setReservationSuccess(true);
          triggerRefresh();
        } else {
          infoMsg('Unfortunately, your reservation was not successful. The available options did not meet our requirements.');
        }
      } catch (error) {
        errorMsg('Service is currently unavailable. Please try again later.');
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  }

  async function handleOnDragEnd(result: any) {
    if (!result.destination) return;

    const items = Array.from(packageOrderWithDetails.orderDetails);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const cardsOrder = items.map((el: any, index) => {
      return {
        packageDetailsId: el.packageDetailsId,
        index: index + 1,
        cardId: el.cartDetails?.cartId,
      };
    });

    setPackageOrderWithDetails((prev: any) => {
      return {
        ...prev,
        orderDetails: items,
      };
    });

    await client.mutate({
      mutation: SAVE_CARDS_ORDER,
      variables: { cardsOrderInput: { cardsOrder } },
    });
  }
  
  const dinnerConfirmationNumber = packageOrderWithDetails?.orderDetails?.find((el: any) => el.group === 'Dinner')?.confirmationNumber;
  
  const linkMdParser = (value: string) => {
    const regex = /^(.*?)\s*\(?\s*(https?:\/\/[^\s)]+)\s*\)?$/;
   
    const matches = value.match(regex);

    if (matches) {
      const label = matches[1].trim() || matches[2];
      const url = matches[2];
      return {
        label: label || value,
        url: url || value
      };
    }

    return {
      label: value,
      url: value
    };
  };
  
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="cards">
        {(providedDroppable) => (
          <div {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
            {packageOrderWithDetails.orderDetails.map(
              (cart: any, cartId: number, orderDetailsArray: any) => {
                const curParsedCartData: any =
                  cart.cartDetails && JSON.parse(cart.cartDetails.cartJsonData);

                const prevParsedCartData: any =
                  cartId !== 0 && orderDetailsArray[cartId - 1].cartDetails
                    ? JSON.parse(orderDetailsArray[cartId - 1].cartDetails.cartJsonData)
                    : null;

                let curDate = curParsedCartData?.date ? curParsedCartData.date : null;
                let prevDate = prevParsedCartData?.date ? prevParsedCartData.date : null;

                if (cart.group === 'Airport Transfer') {
                  curDate =
                    curParsedCartData?.transfer1?.date1 || curParsedCartData
                      ? curParsedCartData?.transfer1?.date1 ||
                        curParsedCartData?.date1 ||
                        curParsedCartData?.date2
                      : null;
                }

                if (cart.group === 'Vegas Limo Transfer') {
                  curDate =
                    curParsedCartData?.transfer1?.pickUp1 || curParsedCartData
                      ? curParsedCartData?.transfer1?.pickUp1 ||
                        curParsedCartData?.pickUp1 ||
                        curParsedCartData?.pickUp2
                      : null;
                }
                
                if (cart.group === 'Vegas Limo Transfer Arrival' || cart.group === 'Vegas Limo Transfer Departure') {
                  curDate = curParsedCartData?.pickUp || null;
                }

                if (
                  cartId !== 0 &&
                  orderDetailsArray[cartId - 1] &&
                  ((orderDetailsArray[cartId - 1].group === 'Airport Transfer') ||
                  (orderDetailsArray[cartId - 1].group === 'Vegas Limo Transfer'))
                ) {
                  prevDate =
                    prevParsedCartData?.transfer1 || prevParsedCartData
                      ? prevParsedCartData?.transfer1?.date1 ||
                        prevParsedCartData?.transfer1?.pickUp1 ||
                        prevParsedCartData?.date1 ||
                        prevParsedCartData?.date2
                      : null;
                }
                
                if (
                  cartId !== 0 &&
                  orderDetailsArray[cartId - 1] &&
                  ((orderDetailsArray[cartId - 1].group === 'Airport Transfer Arrival') ||
                  (orderDetailsArray[cartId - 1].group === 'Airport Transfer Departure') ||
                  (orderDetailsArray[cartId - 1].group === 'Vegas Limo Transfer Arrival') ||
                  (orderDetailsArray[cartId - 1].group === 'Vegas Limo Transfer Departure'))
                ) {
                  prevDate =
                    prevParsedCartData
                      ? prevParsedCartData?.date ||
                        prevParsedCartData?.pickUp
                      : null;
                }

                return (
                  <Draggable
                    index={cartId}
                    draggableId={cart.packageDetailsId.toString()}
                    key={Math.random()}
                  >
                    {(providedDraggable) => (
                      <div
                        {...providedDraggable.dragHandleProps}
                        {...providedDraggable.draggableProps}
                        ref={providedDraggable.innerRef}
                      >
                        {curDate && (moment(curDate).format('l') !== moment(prevDate).format('l')) ? (
                          <div>
                            <h1>{moment(curDate).format('dddd, MMMM D')}</h1>
                          </div>
                        ) : null}
                        <Card>
                          <CardHeader>
                            <Row className="d-flex border-bottom flex-column flex-nowrap pb-2">
                              <div className="d-flex align-items-center">
                                <Col
                                  sm={
                                    cart.type &&
                                    (editableCartTypes.includes(cart.group) ||
                                      FULLY_EDITABLE_CARDS.includes(cart.group)) &&
                                    cart.cartDetails
                                      ? '10'
                                      : '12'
                                  }
                                  className="d-flex align-items-center flex-wrap"
                                >
                                  <h1 className="m-0 pr-4">
                                    {cart.cartDetails?.cardTitle ? cart.cartDetails?.cardTitle : cart.type}
                                    {curParsedCartData?.date || curParsedCartData?.date1 || curParsedCartData?.date2 ? (
                                      <>
                                        &nbsp; — &nbsp;
                                        {moment(curParsedCartData?.date || curParsedCartData?.date1 || curParsedCartData?.date2).format('h:mm a') === '3:30 am'
                                          ? 'Time TBD'
                                          : moment(curParsedCartData?.date || curParsedCartData?.date1 || curParsedCartData?.date2).format('h:mm a')}
                                      </>
                                    ) : null}
                                  </h1>
                                  {editableCartTypes.includes(cart.group) ||
                                  FULLY_EDITABLE_CARDS.includes(cart.group) ? (
                                    <div className="d-flex pr-4">
                                      <Switch
                                        checked={cart.cartDetails?.isPublished}
                                        onClick={async () => {
                                          const { data } = await client.mutate({
                                            mutation: PUBLISH_OR_UNPUBLISH_CART,
                                            variables: { packageDetailsId: cart.packageDetailsId },
                                          });

                                          if (data?.publishOrUnpublichCard === 'ok') {
                                            setPackageOrderWithDetails((prev: any) => {
                                              return {
                                                ...prev,
                                                orderDetails: prev.orderDetails.map((item: any) =>
                                                  item.packageDetailsId === cart.packageDetailsId
                                                    ? {
                                                        ...item,
                                                        cartDetails: {
                                                          ...item.cartDetails,
                                                          isPublished: !prev.orderDetails.find(
                                                            (el: any) =>
                                                              el.packageDetailsId ===
                                                              cart.packageDetailsId,
                                                          ).cartDetails?.isPublished,
                                                        },
                                                      }
                                                    : item,
                                                ),
                                              };
                                            });

                                            successMsg(
                                              cart.cartDetails?.isPublished
                                                ? 'Card is unpublished'
                                                : 'Card is published',
                                            );
                                          }
                                        }}
                                      />
                                      <PublishedLabel
                                        isPublished={!cart.cartDetails?.isPublished}
                                      />
                                    </div>
                                  ) : null}
                                  {cart.cartDetails?.isServiceDeclined &&
                                  cart.cartDetails?.isServiceDeclined === SERVICE_STATE.DECLINED ? (
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={async () => {
                                        if (
                                          window.confirm(
                                            'Are you sure you want to undecline this service?',
                                          )
                                        ) {
                                          const { data } = await client.mutate({
                                            mutation: UNDECLINE_SERVICE,
                                            variables: { packageDetailsId: cart.packageDetailsId },
                                          });

                                          if (data?.undeclineService === 'ok') {
                                            setPackageOrderWithDetails((prev: any) => {
                                              return {
                                                ...prev,
                                                orderDetails: prev.orderDetails.map((item: any) => {
                                                  return item.packageDetailsId ===
                                                    cart.packageDetailsId
                                                    ? {
                                                        ...item,
                                                        cartDetails: {
                                                          ...item.cartDetails,
                                                          isServiceDeclined:
                                                            SERVICE_STATE.UNDECLINED,
                                                        },
                                                      }
                                                    : item;
                                                }),
                                              };
                                            });
                                            successMsg('Service Undeclined');
                                          }
                                        }
                                      }}
                                    >
                                      Undecline Service
                                    </button>
                                  ) : null}
                                </Col>
                                <Col className="text-right">
                                  {cart.type &&
                                  (editableCartTypes.includes(cart.group) ||
                                    FULLY_EDITABLE_CARDS.includes(cart.group)) &&
                                  cart.cartDetails ? (
                                    cart.cartDetails.isServiceDeclined !==
                                    SERVICE_STATE.DECLINED ? (
                                      <button
                                        className="edit-cart-info-btn position-relative"
                                        onClick={() => {
                                          setModalGroup(cart.group);
                                          setModalName(cart.type);
                                          setPackageDetailsId(cart.packageDetailsId);
                                          setIsModalOpened(true);
                                        }}
                                      >
                                        <i className={`ni ni-settings`} />
                                      </button>
                                    ) : (
                                      <>
                                        <span id={`tooltip${cart.packageDetailsId}`}>
                                          <i className={`ni ni-settings`} />
                                        </span>
                                        <UncontrolledTooltip
                                          delay={0}
                                          target={`tooltip${cart.packageDetailsId}`}
                                        >
                                          The service is declined. To edit card please undecline
                                          service.
                                        </UncontrolledTooltip>
                                      </>
                                    )
                                  ) : null}
                                </Col>
                              </div>
                            </Row>
                            {editableCartTypes.includes(cart.group) ? (
                              Object.entries(CARD_FORM_SCHEMA).map(([key, value], id) => {
                                const parsedCardDetails = JSON.parse(cart.cartDetails.cartJsonData);

                                if (cart.group === key) {
                                  if (key === 'Airport Transfer') {
                                    const cartSchemaConfig = parsedCardDetails.transferId
                                      ? value[
                                          `transfer${parsedCardDetails.transferId}` as keyof typeof value
                                        ]
                                      : value[airportTransferType as keyof typeof value];

                                    return (
                                      <Cart
                                        cartSchemaConfig={cartSchemaConfig}
                                        cartData={cart}
                                        airportTransferType={airportTransferType}
                                        setAirportTransferType={setAirportTransferType}
                                        dataKey={key}
                                        isCartInfoOpenedId={isCartInfoOpenedId}
                                        handleOpenDetails={handleOpenDetails}
                                        parsedCardDetails={
                                          parsedCardDetails[
                                            airportTransferType as keyof typeof value
                                          ]
                                        }
                                        key={id}
                                      />
                                    );
                                  }
                                  
                                  if (key === 'Vegas Limo Transfer') {
                                    const cartSchemaConfig = parsedCardDetails.transferId
                                      ? value[
                                          `transfer${parsedCardDetails.transferId}` as keyof typeof value
                                        ]
                                      : value[vegasLimoTransferType as keyof typeof value];

                                    return (
                                      <Cart
                                        cartSchemaConfig={cartSchemaConfig}
                                        cartData={cart}
                                        vegasLimoTransferType={vegasLimoTransferType}
                                        setVegasLimoTransferType={setVegasLimoTransferType}
                                        dataKey={key}
                                        isCartInfoOpenedId={isCartInfoOpenedId}
                                        handleOpenDetails={handleOpenDetails}
                                        parsedCardDetails={
                                          parsedCardDetails[
                                            vegasLimoTransferType as keyof typeof value
                                          ]
                                        }
                                        key={id}
                                      />
                                    );
                                  }

                                  return (
                                    <Cart
                                      cartSchemaConfig={value}
                                      cartData={cart}
                                      airportTransferType={airportTransferType}
                                      setAirportTransferType={setAirportTransferType}
                                      vegasLimoTransferType={vegasLimoTransferType}
                                      setVegasLimoTransferType={setVegasLimoTransferType}
                                      dataKey={key}
                                      isCartInfoOpenedId={isCartInfoOpenedId}
                                      handleOpenDetails={handleOpenDetails}
                                      parsedCardDetails={parsedCardDetails}
                                      cartInfoId={id}
                                      handleOpenLearnMore={handleOpenLearnMore}
                                      isLearnMoreOpenedId={isLearnMoreOpenedId}
                                      key={id}
                                    />
                                  );
                                }
                              })
                            ) : FULLY_EDITABLE_CARDS.includes(cart.group) && curParsedCartData ? (
                              <div style={{ marginTop: '20px' }}>
                                {Array.isArray(curParsedCartData) &&
                                  curParsedCartData?.map((field: any, id: number) => {
                                    return Object.entries(field).map(([key, value]: any) => {
                                      const isCheckbox = key === 'ticketsSent';
                                      const isLink = key.toLowerCase() === 'link';
                                      return (
                                        <Row style={{ marginTop: '6px' }} key={id}>
                                          <Col>
                                            <span className="text-capitalize">{key}</span>
                                          </Col>
                                          <Col>
                                          {isCheckbox && 
                                            <input type="checkbox" checked={value} readOnly/>
                                          }
                                          {isLink &&
                                            <a href={linkMdParser(value).url} target="_blank" rel="noreferrer">{linkMdParser(value).label}</a>
                                          }
                                          {!isLink && !isCheckbox && <span>{value ? value : '-'}</span>}
                                          </Col>
                                        </Row>
                                      );
                                    });
                                  })}
                              </div>
                            ) : (
                              <Cart
                                cartSchemaConfig={DEFAULT_CARD_CONFIG}
                                parsedCardDetails={cart}
                              />
                            )}
                            {cart.group === 'Dinner' && 
                              <Col className="m-2">
                                {isLoading ? (
                                  <div className="d-flex justify-content-end align-items-center">
                                    <span className="m-2 text-danger">Your reservation is in progress. Please refrain from refreshing the page or editing information on the invoice.</span>
                                    <Row className='ml-2'>
                                      <Spinner size="sm" animation="border" variant="info" />
                                    </Row>
                                  </div>
                                ) : dinnerConfirmationNumber || reservationSuccess ? (
                                  <div className="d-flex justify-content-end">
                                    <input className="mr-2 p-1" type="checkbox" checked readOnly /> 
                                    <span>Already reserved</span>
                                  </div>
                                ) : (
                                  <div className="d-flex justify-content-end">
                                    <button 
                                      className="btn btn-sm btn-primary" 
                                      onClick={handleMakeReservation}
                                    >
                                      <i className="fa fa-utensils"></i>&nbsp;&nbsp;Reserve Now
                                    </button>
                                  </div>
                                )}
                              </Col>
                            }
                          </CardHeader>
                          
                        </Card>
                      </div>
                    )}
                  </Draggable>
                );
              },
            )}
            {providedDroppable.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default CartsContainer;
