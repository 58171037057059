import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useApolloClient } from '@apollo/client';
import { Col, FormGroup, FormText, Input } from 'reactstrap';
import { successMsg } from '../../../cache/vars';
import {
  ADD_ROW_TO_FANATIC_CREDIT,
  ADD_ROW_TO_MERCHANDISE_CREDIT,
  REMOVE_ROW_FROM_MERCHANDISE_CREDIT,
} from '../../../queries/vip-workflow';
import { IFormSection } from '../../../utils/interfaces';
import TransferTypeButtons from '../../Buttons/TransferTypeButtons';
import GooglePlacesAutocompleteInput from '../../GooglePlacesAutocomplete/GooglePlacesAutocomplete';
import moment from 'moment';
import { DatePicker, Space } from 'antd';

const FormSection = ({
  value,
  handleInputs,
  cartKey,
  setAirportTransferType,
  airportTransferType,
  setVegasLimoTransferType,
  vegasLimoTransferType,
  modalState,
  hanldeRevertCartToOriginal,
  setModalState,
}: IFormSection) => {
  const client = useApolloClient();

  const [selectedType, setSelectedType] = useState('');
  const [descriptionEGiftCode, setDescriptionEGiftCode] = useState('');
  const [descriptionELink, setDescriptionELink] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);

  const [inputValue, setInputValue] = useState('');
  const [inputValuePosition, setInputValuePosition] = useState(0);
  const [displayPhoneValue, setDisplayPhoneValue] = useState(modalState.cartData?.contactNumber || '');
  
  const [vendorPhoneValue, setVendorPhoneValue] = useState(modalState.cartData?.vendorPhone || '');
  const [displayVendorPhoneValue, setDisplayVendorPhoneValue] = useState(modalState.cartData?.vendorPhone || '');
  
  const handlePhoneChange = (e: { target: { value: string; }; }) => {
    let newValue = e.target.value.replace(/[^+\d]/g, "");
  
    const digitsCount = newValue.replace(/\D/g, "").length;
    if (digitsCount > 16) {
      newValue = newValue.slice(0, newValue.length - (digitsCount - 16));
    }
  
    setInputValue(newValue);
    setDisplayPhoneValue(newValue);
  };
  
  const handleBlur = () => {
    let modifiedPhone = inputValue;

    if (modifiedPhone.startsWith('+1')) {
      modifiedPhone = modifiedPhone.slice(2).trim();
    } else if (modifiedPhone.startsWith('+')) {
      modifiedPhone = modifiedPhone.slice(1).trim();
    }
  
    setDisplayPhoneValue(modifiedPhone);
    
    if (setModalState) {
      setModalState((prev: any) => {
        return {
          ...prev,
          cartData: {
            ...prev.cartData,
            contactNumber: modifiedPhone,
          },
        };
      });
    }
  };
  
  const handleFocus = () => {
    setDisplayPhoneValue(inputValue);
  };
  
  const handleVendorPhoneChange = (e: { target: { value: string; }; }) => {
    let newValue = e.target.value.replace(/[^+\d]/g, "");
    const digitsCount = newValue.replace(/\D/g, "").length;
    if (digitsCount > 16) newValue = newValue.slice(0, newValue.length - (digitsCount - 16));
    setVendorPhoneValue(newValue);
    setDisplayVendorPhoneValue(newValue);
  };
  
  const handleVendorPhoneBlur = () => {
    let modifiedVendorPhone = vendorPhoneValue;
    if (modifiedVendorPhone.startsWith('+1')) modifiedVendorPhone = modifiedVendorPhone.slice(2).trim();
    else if (modifiedVendorPhone.startsWith('+')) modifiedVendorPhone = modifiedVendorPhone.slice(1).trim();
    setDisplayVendorPhoneValue(modifiedVendorPhone);
    
    if (setModalState) {
      setModalState((prev: any) => {
        return {
          ...prev,
          cartData: {
            ...prev.cartData,
            vendorPhone: modifiedVendorPhone,
          },
        };
      });
    }
      
  };
  
  const handleVendorPhoneFocus = () => setDisplayVendorPhoneValue(vendorPhoneValue);
  

  useEffect(() => {
    if (!selectedType && modalState?.cartData) {
      if (Array.isArray(modalState?.cartData) && modalState.cartData.some((item: any) => 'eLink' in item)) {
        setSelectedType('eLink');
      } else {
        setSelectedType('eGiftCode');
      }
      if (descriptionEGiftCode === '' && descriptionELink === '') {
        // const description = (Array.isArray(modalState.cartData) && modalState.cartData[0] && modalState.cartData[0]?.description) ? modalState.cartData[0]?.description : '';
        const description = Array.isArray(modalState.cartData) && modalState.cartData.find((obj: Record<string, any>) => obj.hasOwnProperty('description'))?.description;
        const [eGiftCode, eLink] = typeof description === 'string' ? description.split('${or}') : ['', ''];
        setDescriptionEGiftCode(eGiftCode);
        setDescriptionELink(eLink);
      }
      if (modalState.cartData) {
        setDisplayPhoneValue(modalState.cartData.contactNumber || '');
        setDisplayVendorPhoneValue(modalState.cartData.vendorPhone || '');
        setInputValue(modalState.cartData.contactNumber || '');
        setVendorPhoneValue(modalState.cartData.vendorPhone || '');
      }
    }
  }, [modalState]);

  const handleTypeChange = (e: any) => {
    setSelectedType(e.target.value);
  };
  
  const handleKeyDown = (e: any) => {
    if (['Shift', 'Control', 'Alt', 'Meta'].includes(e.key)) {
      return;
    }
    
    if (e.key === 'Enter') {
      e.target.blur();
    }  else if (e.key === 'Backspace') {
      setInputValue((prevValue) => prevValue.slice(0, -1));
    }  else if (e.key === 'Delete') {
      setInputValue((prevValue) => prevValue.slice(0, inputValuePosition) + prevValue.slice(inputValuePosition + 1));
    } else if (e.key.length === 1) {
      setInputValue((prevValue) => prevValue + e.key);
    }
  };

  switch (cartKey) {
    case 'Airport Transfer':
      return (
        <>
          <TransferTypeButtons
            airportTransferType={airportTransferType}
            setAirportTransferType={setAirportTransferType}
          />
          <div className="mt-4 d-flex flex-wrap">
            {value?.map((field: any, index: number) => {
              if (field.skipIfInput) {
                return;
              }

              if (field.cartInfo) {
                return Object.entries(field).map(([key, config]: any, id) => {
                  if (key === 'cartInfo') return;
                  return (
                    <Col xs="6" key={id}>
                      <FormGroup key={id} className="mr-3">
                        <label className="form-control-label">{config.label}</label>
                        <Input
                          className="form-control-alternative new-event--title"
                          type={config.inputType}
                          name={config.name}
                          placeholder={config.placeholder}
                          onChange={handleInputs}
                          value={modalState[config.name] || ''}
                          min={field.inputType === 'number' && field.min ? field.min : null}
                        />
                      </FormGroup>
                    </Col>
                  );
                });
              }

              if (field.isGooglePlaceInput) {
                return (
                  <Col xs="6" key={index}>
                    <FormGroup className="mr-3">
                      <label className="form-control-label">
                        {typeof field.label === 'function' ? field.label(null, true) : field.label}
                      </label>
                      <GooglePlacesAutocompleteInput
                        modalState={modalState}
                        setModalState={setModalState}
                        fieldConfig={field}
                        airportTransferType={airportTransferType}
                        cartKey={cartKey}
                      />
                      {!modalState[field.name] ? (
                        <FormText>{modalState[field.showDataFrom]}</FormText>
                      ) : (
                        <FormText>{modalState[field.name]}</FormText>
                      )}
                    </FormGroup>
                  </Col>
                );
              }

              return (
                <Col xs="6" key={index}>
                  <FormGroup key={index} className="mr-3">
                    <label className="form-control-label">
                      {typeof field.label === 'function' ? field.label(null, true) : field.label}
                    </label>
                    <Input
                      className="form-control-alternative new-event--title"
                      type={field.inputType}
                      name={field.name}
                      placeholder={field.placeholder}
                      onChange={(e) => handleInputs(e, airportTransferType)}
                      value={modalState[field.name] || ''}
                      min={field.inputType === 'number' && field.min ? field.min : null}
                    />
                  </FormGroup>
                </Col>
              );
            })}
          </div>
        </>
      );
    case 'Vegas Limo Transfer':
        return (
          <>
            <TransferTypeButtons
              vegasLimoTransferType={vegasLimoTransferType}
              setVegasLimoTransferType={setVegasLimoTransferType}
            />
            <div className="mt-4 d-flex flex-wrap">
              {value?.map((field: any, index: number) => {
                if (field.skipIfInput) {
                  return null;
                }
                
                if (field.cartInfo) {
                  return Object.entries(field).map(([key, config]: any, id) => {
                    if (key === 'cartInfo') return null;
                    return (
                      <Col xs="6" key={id}>
                        <FormGroup className="mr-3">
                          <label className="form-control-label">{config.label}</label>
                          <Input
                            className="form-control-alternative new-event--title"
                            type={config.inputType}
                            name={config.name}
                            placeholder={config.placeholder}
                            onChange={handleInputs}
                            value={modalState[config.name] || ''}
                            min={config.inputType === 'number' && config.min ? config.min : null}
                          />
                        </FormGroup>
                      </Col>
                    );
                  });
                }
      
                if (field.isGooglePlaceInput) {
                  const inputElement = (
                    <Col xs="6" key={`google-${index}`}>
                      <FormGroup className="mr-3">
                        <label className="form-control-label">{field.label}</label>
                        <GooglePlacesAutocompleteInput
                          modalState={modalState}
                          setModalState={setModalState}
                          fieldConfig={field}
                          vegasLimoTransferType={vegasLimoTransferType}
                          cartKey={cartKey}
                        />
                        {!modalState[field.name] ? (
                          <FormText>{modalState[field.showDataFrom]}</FormText>
                        ) : (
                          <FormText>{modalState[field.name]}</FormText>
                        )}
                      </FormGroup>
                    </Col>
                  );
      
                  if (field.label === 'Pick Up Address') {
                    return (
                      <>
                        {inputElement}
                        <Col xs="6" key={`empty-space-after-pickup-${index}`} style={{ height: '58px' }}></Col>
                      </>
                    );
                  }
      
                  return inputElement;
                }
      
                return (
                  <Col xs="6" key={index}>
                    <FormGroup className="mr-3">
                      <label className="form-control-label">{field.label}</label>
                      <Input
                        className="form-control-alternative new-event--title"
                        type={field.inputType}
                        name={field.name}
                        placeholder={field.placeholder}
                        onChange={(e) => handleInputs(e, vegasLimoTransferType)}
                        value={modalState[field.name] || ''}
                        min={field.inputType === 'number' && field.min ? field.min : null}
                      />
                    </FormGroup>
                  </Col>
                );
              })}
            </div>
          </>
        );
    case 'NHL Card':
    case 'MLB Card':
    case 'NBA Card':
    case 'Multi-day Gift Card':
      return (
        <div className="d-flex flex-wrap">
          {modalState.cartData?.length &&
            modalState.cartData?.map((item: any, id: number, array: any[]) => {
              return value.map((field: any, index: number) => {
                if (field.skipIfInput) {
                  return null;
                }

                if (item.hasOwnProperty(field.name)) {
                  if (field.inputType === 'textarea') {
                    return id === array.length - 1 ? (
                      <Col xs="12" key={index}>
                        <button
                          style={{ background: 'none', border: 'none', boxShadow: 'none' }}
                          className="btn mb-3 pl-0 pr-0"
                          onClick={async (e) => {
                            e.preventDefault();

                            const { data } = await client.mutate({
                              mutation: ADD_ROW_TO_MERCHANDISE_CREDIT,
                              variables: {
                                packageDetailsId: modalState.packageDetailsId,
                              },
                            });

                            if (setModalState && data) {
                              setModalState((prev: any) => {
                                return {
                                  ...prev,
                                  cartId: data?.addRowToMerchandiseCredit?.cartId || null,
                                  cartData: [
                                    ...prev.cartData.slice(0, prev.cartData.length - 1),
                                    { eGiftCode: '', PIN: '' },
                                    ...prev.cartData.slice(-1),
                                  ],
                                };
                              });
                            }

                            successMsg('Row Added');
                          }}
                        >
                          + Add Row
                        </button>
                        <FormGroup className="mr-3">
                          <label className="form-control-label">
                            {typeof field.label === 'function'
                              ? field.label(null, true)
                              : field.label}
                          </label>
                          <Input
                            className="form-control-alternative new-event--title w-100 p-3"
                            name={field.name}
                            placeholder={field.placeholder}
                            onChange={(e) => {
                              if (setModalState) {
                                setModalState((prev: any) => {
                                  return {
                                    ...prev,
                                    cartData: prev.cartData.map((el: any, idx: number) => {
                                      return id === idx
                                        ? { ...el, [field.name]: e.target.value }
                                        : el;
                                    }),
                                  };
                                });
                              }
                            }}
                            value={item?.[field.name] || ''}
                            rows={6}
                            type="textarea"
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col xs="12" key={index}>
                        <FormGroup className="mr-3">
                          <label className="form-control-label">
                            {typeof field.label === 'function'
                              ? field.label(null, true)
                              : field.label}
                          </label>
                          <Input
                            className="form-control-alternative new-event--title w-100 p-3"
                            name={field.name}
                            placeholder={field.placeholder}
                            onChange={(e) => {
                              if (setModalState) {
                                setModalState((prev: any) => {
                                  return {
                                    ...prev,
                                    cartData: prev.cartData.map((el: any, idx: number) => {
                                      return id === idx
                                        ? { ...el, [field.name]: e.target.value }
                                        : el;
                                    }),
                                  };
                                });
                              }
                            }}
                            value={item?.[field.name] || ''}
                            rows={6}
                            type="textarea"
                          />
                        </FormGroup>
                      </Col>
                    );
                  }

                  return (
                    <Col xs="6" key={index}>
                      <div className="d-flex">
                        <FormGroup className="mr-3 w-100">
                          <div className="d-flex justify-content-between">
                            <label className="form-control-label">{field.label}</label>
                          </div>
                          <Input
                            className="form-control-alternative new-event--title"
                            type={field.inputType}
                            name={field.name}
                            placeholder={`Enter ${field.label}`}
                            onChange={(e) => {
                              if (setModalState) {
                                setModalState((prev: any) => {
                                  return {
                                    ...prev,
                                    cartData: prev.cartData.map((el: any, idx: number) => {
                                      return id === idx
                                        ? { ...el, [field.name]: e.target.value }
                                        : el;
                                    }),
                                  };
                                });
                              }
                            }}
                            value={item[field.name] || ''}
                          />
                        </FormGroup>
                        {field.name === 'PIN' ? (
                          <div className="d-flex align-items-center mt-1">
                            <button
                              style={{ background: 'none', border: 'none', boxShadow: 'none' }}
                              onClick={async (e) => {
                                e.preventDefault();

                                const { data } = await client.mutate({
                                  mutation: REMOVE_ROW_FROM_MERCHANDISE_CREDIT,
                                  variables: {
                                    rowId: id,
                                    packageDetailsId: modalState.packageDetailsId,
                                  },
                                });

                                if (setModalState && data) {
                                  setModalState((prev: any) => {
                                    return {
                                      ...prev,
                                      cartId: data?.removeRowFromMerchandiseCredit?.cartId || null,
                                      cartData: [
                                        ...prev.cartData.filter(
                                          (item: any, index: number) => index !== id,
                                        ),
                                      ],
                                    };
                                  });
                                }

                                successMsg('Row Removed');
                              }}
                            >
                              <i className="fa fa-regular fa-trash"></i>
                            </button>
                          </div>
                        ) : (
                          <div style={{ height: '0', width: '0' }}></div>
                        )}
                      </div>
                    </Col>
                  );
                }
              });
            })}
        </div>
      );
    case 'Fanatics Credit':
      return (
        <div className="d-flex flex-wrap">
          <FormGroup className="ml-3 mr-3">
            <label className="form-control-label">Type</label>
            <Input type="select" value={selectedType} onChange={handleTypeChange}>
              <option value="eGiftCode">eGiftCode</option>
              <option value="eLink">URL</option>
            </Input>
          </FormGroup>
          {modalState.cartData?.length &&
            modalState.cartData?.map((item: any, id: number, array: any[]) => {
              return value.map((field: any, index: number) => {
                if (field.skipIfInput) {
                  return null;
                }

                if (selectedType === 'eLink' && 'eGiftCode' in item) {
                  return null;
                }
                if (selectedType === 'eGiftCode' && 'eLink' in item) {
                  return null;
                }
                
                if (item.hasOwnProperty(field.name)) {
                  if (field.inputType === 'textarea') {
                    return id === array.length - 1 ? (
                      <Col xs="12" key={index}>
                        <button
                          style={{ background: 'none', border: 'none', boxShadow: 'none' }}
                          className="btn mb-3 pl-0 pr-0"
                          onClick={async (e) => {
                            e.preventDefault();

                            const { data } = await client.mutate({
                              mutation: ADD_ROW_TO_FANATIC_CREDIT,
                              variables: {
                                packageDetailsId: modalState.packageDetailsId,
                                isLink: selectedType === 'eLink',
                              },
                            });
                            if (setModalState && data) {
                              setModalState((prev: any) => {
                                return {
                                  ...prev,
                                  cartId: data?.addRowToFanaticCredit?.cartId || null,
                                  cartData: [
                                    ...prev.cartData.slice(0, prev.cartData.length - 1),
                                    selectedType === 'eLink' ? { eLink: '' } : { eGiftCode: '', PIN: '' },
                                    ...prev.cartData.slice(-1),
                                  ],
                                };
                              });
                            }

                            successMsg('Row Added');
                          }}
                        >
                          + Add Row
                        </button>
                        <FormGroup className="mr-3">
                          <label className="form-control-label">
                            {typeof field.label === 'function'
                              ? field.label(null, true)
                              : field.label}
                          </label>
                          <Input
                            className="form-control-alternative new-event--title w-100 p-3"
                            name={field.name}
                            placeholder={field.placeholder}
                            onChange={(e) => {
                              if (setModalState) {
                                setModalState((prev: any) => {
                                  return {
                                    ...prev,
                                    cartData: prev.cartData.map((el: any, idx: number) => {
                                      return id === idx
                                        ? { ...el, [field.name]: e.target.value }
                                        : el;
                                    }),
                                  };
                                });
                              }
                            }}
                            value={item?.[field.name] || ''}
                            rows={6}
                            type="textarea"
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col xs="12" key={index}>
                        <FormGroup className="mr-3">
                          <label className="form-control-label">
                            {typeof field.label === 'function'
                              ? field.label(null, true)
                              : field.label}
                          </label>
                          {/* <Input
                            className="form-control-alternative new-event--title w-100 p-3"
                            name={field.name}
                            placeholder={field.placeholder}
                            onChange={(e) => {
                              if (selectedType === 'eLink') {
                                setDescriptionELink(e.target.value);
                              } 
                              if (selectedType === 'eGiftCode') {
                                setDescriptionEGiftCode(e.target.value);
                              }
                              if (setModalState) {
                                setModalState((prev: any) => {
                                  return {
                                    ...prev,
                                    cartData: prev.cartData.map((el: any, idx: number) => {
                                      return id === idx
                                        ? { ...el, [field.name]: descriptionEGiftCode + '${or}' + descriptionELink}
                                        : el;
                                    }),
                                  };
                                });
                              }
                            }}
                            value={selectedType === 'eLink' ? descriptionELink : descriptionEGiftCode}
                            rows={6}
                            type="textarea"
                          /> */}
                          <Input
                            className="form-control-alternative new-event--title w-100 p-3"
                            name={field.name}
                            placeholder={field.placeholder}
                            onChange={(e) => {
                              const value = e.target.value;

                              if (selectedType === 'eLink') {
                                setDescriptionELink(value);
                              } 
                              if (selectedType === 'eGiftCode') {
                                setDescriptionEGiftCode(value);
                              }

                              if (setModalState) {
                                setModalState((prev: any) => {
                                  return {
                                    ...prev,
                                    cartData: prev.cartData.map((el: any, idx: number) => {
                                      if (id === idx) {
                                        const updatedDescription =
                                          (selectedType === 'eGiftCode' ? value : descriptionEGiftCode) +
                                          '${or}' +
                                          (selectedType === 'eLink' ? value : descriptionELink);
                                        return { ...el, [field.name]: updatedDescription };
                                      }
                                      return el;
                                    }),
                                  };
                                });
                              }
                            }}
                            value={selectedType === 'eLink' ? descriptionELink : descriptionEGiftCode}
                            rows={6}
                            type="textarea"
                          />

                        </FormGroup>
                      </Col>
                    );
                  }

                  return (
                    <Col xs="6" key={index}>
                      <div className="d-flex">
                        <FormGroup className="mr-3 w-100">
                          <div className="d-flex justify-content-between">
                            <label className="form-control-label">{field.label}</label>
                          </div>
                          <Input
                            className="form-control-alternative new-event--title"
                            type={field.inputType}
                            name={field.name}
                            placeholder={`Enter ${field.label}`}
                            onChange={(e) => {
                              if (setModalState) {
                                setModalState((prev: any) => {
                                  return {
                                    ...prev,
                                    cartData: prev.cartData.map((el: any, idx: number) => {
                                      return id === idx
                                        ? { ...el, [field.name]: e.target.value }
                                        : el;
                                    }),
                                  };
                                });
                              }
                            }}
                            value={item[field.name] || ''}
                          />
                        </FormGroup>
                        {field.name === 'PIN' || field.name === 'eLink' ? (
                          <div className="d-flex align-items-center mt-1">
                            <button
                              style={{ background: 'none', border: 'none', boxShadow: 'none' }}
                              onClick={async (e) => {
                                e.preventDefault();

                                const { data } = await client.mutate({
                                  mutation: REMOVE_ROW_FROM_MERCHANDISE_CREDIT,
                                  variables: {
                                    rowId: id,
                                    packageDetailsId: modalState.packageDetailsId,
                                  },
                                });

                                if (setModalState && data) {
                                  setModalState((prev: any) => {
                                    return {
                                      ...prev,
                                      cartId: data?.removeRowFromMerchandiseCredit?.cartId || null,
                                      cartData: [
                                        ...prev.cartData.filter(
                                          (item: any, index: number) => index !== id,
                                        ),
                                      ],
                                    };
                                  });
                                }

                                successMsg('Row Removed');
                              }}
                            >
                              <i className="fa fa-regular fa-trash"></i>
                            </button>
                          </div>
                        ) : (
                          <div style={{ height: '0', width: '0' }}></div>
                        )}
                      </div>
                    </Col>
                  );
                }

                return null;
              });
            })}
        </div>
      );
    default:
      return (
        <div className="d-flex flex-wrap">
          {value?.map((field: any, index: number) => {
            const hideFieldIfNoValue =
              field.showIfFieldHasValue &&
              !modalState.cartData?.[field.showIfFieldHasValue] &&
              !field.showIfValueEqualsTo;

            const hideFieldIfValueNotEqualToGivenValue =
              field.showIfFieldHasValue &&
              field.showIfValueEqualsTo &&
              modalState.cartData?.[field.showIfFieldHasValue] !== field.showIfValueEqualsTo;
              
            if (field.name === 'contactNumber') {
              return (
                <Col xs="6" key={index}>
                  <FormGroup className="mr-3">
                    <label className="form-control-label">{field.label}</label>
                    <InputMask
                      mask={inputValue.startsWith('+1')
                        ? ["+", "1", " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/]
                        : "+999 999 9999 9999 9999"}
                      maskChar={null}
                      alwaysShowMask={false}
                      value={displayPhoneValue}
                      onChange={handlePhoneChange}
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      className="form-control form-control-alternative new-event--title"
                      placeholder="+1 234 567 890"
                    />
                  </FormGroup>
                </Col>
              );
            }

            if (field.learnMore) {
              return (
                <Col className="mb-3" key={index}>
                  <span>
                    {field.render(
                      modalState.cartData?.[field.name],
                      modalState.cartData?.[field.showDataFrom],
                    )}
                  </span>
                </Col>
              );
            }

            if (field.skipIfInput || hideFieldIfNoValue || hideFieldIfValueNotEqualToGivenValue) {
              return null;
            }

            if (field.cartInfo) {
              return Object.entries(field).map(([key, config]: any, id) => {
                if (key === 'cartInfo' || config.skipIfInput) return null;
                
                if (key === 'vendorPhone') {
                  return (
                    <Col xs="6" key={id}>
                      <FormGroup className="mr-3">
                        <label className="form-control-label">{config.label}</label>
                        <InputMask
                          mask={vendorPhoneValue.startsWith('+1')
                            ? ["+", "1", " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/]
                            : "+999 999 9999 9999 9999"}
                          maskChar={null}
                          alwaysShowMask={false}
                          value={displayVendorPhoneValue}
                          onChange={handleVendorPhoneChange}
                          onBlur={handleVendorPhoneBlur}
                          onFocus={handleVendorPhoneFocus}
                          className="form-control form-control-alternative new-event--title"
                          placeholder="+1 234 567 890"
                        />
                      </FormGroup>
                    </Col>
                  );
                }

                if (field.inputType === 'number') {
                  return (
                    <Col xs="6" key={id}>
                      <FormGroup className="mr-3">
                        <label className="form-control-label">
                          {typeof field.label === 'function'
                            ? field.label(null, true)
                            : field.label}
                        </label>
                        <Input
                          className="form-control-alternative new-event--title ml-4"
                          type={field.inputType}
                          name={field.name}
                          placeholder={field.placeholder}
                          onChange={handleInputs}
                          value={modalState.cartData?.[field.name] || ''}
                          min={field.min}
                        />
                      </FormGroup>
                    </Col>
                  );
                }

                if (config.isGooglePlaceInput) {
                  return (
                    <Col xs="6" key={id}>
                      <FormGroup className="mr-3 d-flex flex-column">
                        <label className="form-control-label">
                          {typeof config.label === 'function'
                            ? config.label(null, true)
                            : config.label}
                        </label>
                        <GooglePlacesAutocompleteInput
                          modalState={modalState}
                          setModalState={setModalState}
                          fieldConfig={config}
                          cartKey={cartKey}
                        />
                        {!modalState.cartData?.[config.name] ? (
                          <FormText>{modalState.cartData?.[config.showDataFrom]}</FormText>
                        ) : (
                          <FormText>{modalState.cartData?.[config.name]}</FormText>
                        )}
                      </FormGroup>
                    </Col>
                  );
                }

                return (
                  <Col xs="6" key={id}>
                    <FormGroup className="mr-3">
                      <div className="d-flex justify-content-between">
                        <label className="form-control-label">{config.label}</label>
                        {modalState.cartData?.[config.isEventDataWasModified] ? (
                          <button
                            className="text-primary revert-btn"
                            onClick={(e) => {
                              e.preventDefault();
                              hanldeRevertCartToOriginal(
                                modalState.packageOrderId,
                                modalState.cartId,
                                config.keyForReveringValue,
                              );
                            }}
                          >
                            Revert to original
                          </button>
                        ) : null}
                      </div>
                      <Input
                        className="form-control-alternative new-event--title"
                        type={config.inputType}
                        name={config.name}
                        placeholder={config.placeholder}
                        onChange={handleInputs}
                        value={modalState.cartData?.[config.name] || ''}
                        min={field.inputType === 'number' && field.min ? field.min : null}
                      />
                    </FormGroup>
                  </Col>
                );
              });
            }

            if (field.isGooglePlaceInput) {
              return (
                <Col xs="6" key={index}>
                  <FormGroup className="mr-3">
                    <label className="form-control-label">
                      {typeof field.label === 'function' ? field.label(null, true) : field.label}
                    </label>
                    <GooglePlacesAutocompleteInput
                      modalState={modalState}
                      setModalState={setModalState}
                      fieldConfig={field}
                      cartKey={cartKey}
                    />
                    {!modalState.cartData?.[field.name] ? (
                      <FormText>{modalState.cartData?.[field.showDataFrom]}</FormText>
                    ) : (
                      <FormText>{modalState.cartData?.[field.name]}</FormText>
                    )}
                  </FormGroup>
                </Col>
              );
            }
            
            if (field.inputType === 'date' && cartKey === 'Ticket' && field.name === 'ticketsSentDate') {
              return (
                <Col xs="6" key={index}>
                  <FormGroup className="mr-3">
                    <label className="form-control-label">
                      {typeof field.label === 'function' ? field.label(null, true) : field.label}
                    </label>
                    <Space direction="vertical" className="d-flex w-full">
                      <DatePicker
                        className="d-flex w-full border-0 form-control-alternative new-event--title form-control"
                        allowClear={false}
                        placeholder={field.placeholder || 'Select Date'}
                        onBlur={(e)=>{
                          const isValid = moment(inputValue).isValid();
                          if (isValid) {
                            const event = {
                              target: {
                                name: field.name,
                                value: moment(inputValue),
                              },
                            }
                            handleInputs(event);
                            setInputValue('');
                          }
                        }}
                        onKeyDown={handleKeyDown}
                        onClick={(e)=>{
                          setInputValue('');
                          setInputValuePosition(0);
                          const event = {
                            target: {
                              name: field.name,
                              value: '',
                            },
                          }
                          handleInputs(event);
                        }}
                        format={field.defaultFormat || "YYYY-MM-DD"}
                        value={modalState.cartData?.[field.name] ? moment(modalState.cartData?.[field.name]) : null}
                        onChange={(date, dateString) => {
                          const event = {
                            target: {
                              name: field.name,
                              value: date,
                            },
                          }
                          handleInputs(event);
                        }}
                      />
                    </Space>
                  </FormGroup>
                </Col>
              );
            }
            
            if (field.inputType === 'date' && cartKey === 'Tailgate' && field.name === 'ticketsSentDate') {
              return (
                <Col xs="6" key={index}>
                  <FormGroup className="mr-3">
                    <label className="form-control-label">
                      {typeof field.label === 'function' ? field.label(null, true) : field.label}
                    </label>
                    <Space direction="vertical" className="d-flex w-full">
                      <DatePicker
                        className="d-flex w-full border-0 form-control-alternative new-event--title form-control"
                        allowClear={false}
                        placeholder={field.placeholder || 'Select Date'}
                        onBlur={(e)=>{
                          const isValid = moment(inputValue).isValid();
                          if (isValid) {
                            const event = {
                              target: {
                                name: field.name,
                                value: moment(inputValue),
                              },
                            }
                            handleInputs(event);
                            setInputValue('');
                          }
                        }}
                        onKeyDown={handleKeyDown}
                        onClick={(e)=>{
                          setInputValue('');
                          setInputValuePosition(0);
                          const event = {
                            target: {
                              name: field.name,
                              value: '',
                            },
                          }
                          handleInputs(event);
                        }}
                        format={field.defaultFormat || "YYYY-MM-DD"}
                        value={modalState.cartData?.[field.name] ? moment(modalState.cartData?.[field.name]) : null}
                        onChange={(date, dateString) => {
                          const event = {
                            target: {
                              name: field.name,
                              value: date,
                            },
                          }
                          handleInputs(event);
                        }}
                      />
                    </Space>
                  </FormGroup>
                </Col>
              );
            }
            

            if (field.inputType === 'date') {
              return (
                <Col xs="6" key={index}>
                  <FormGroup className="mr-3">
                    <label className="form-control-label">
                      {typeof field.label === 'function' ? field.label(null, true) : field.label}
                    </label>
                    <Input
                      className="form-control-alternative new-event--title"
                      type={field.inputType}
                      name={field.name}
                      placeholder={field.placeholder}
                      onChange={handleInputs}
                      value={moment(modalState.cartData?.[field.name]).format('YYYY-MM-DD') || ''}
                      disabled={field.isDisabled}
                    />
                  </FormGroup>
                </Col>
              );
            }

            if (field.inputType === 'select' && (cartKey === 'Ticket' || cartKey === 'Tailgate')) {
            return (
                <Col xs="6" key={index}>
                  <FormGroup className="mr-3">
                    <label className="form-control-label">{field.label}</label>
                    <Input
                      className="form-control-alternative new-event--title"
                      type={field.inputType}
                      name={field.name}
                      placeholder={field.placeholder}
                      onChange={handleInputs}
                      value={Number(modalState.cartData?.[field.name]) || ''}
                    >
                      {field.options.map((option: Record<string, any>) => {
                        return <option key={option.id} value={option.id}>{option.name}</option>;
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              );
            }
            if (field.inputType === 'select' && cartKey === 'Parking') {
              return (
                <Col xs="6" key={index}>
                  <FormGroup className="mr-3">
                    <label className="form-control-label">{field.label}</label>
                    <Input
                      className="form-control-alternative new-event--title"
                      type={field.inputType}
                      name={field.name}
                      placeholder={field.placeholder}
                      onChange={handleInputs}
                      value={modalState.cartData?.[field.name] || ''}
                    >
                      {field.options.map((option: string) => {
                        return <option>{option}</option>;
                      }
                      )}
                    </Input>
                  </FormGroup>
                </Col>
              );
            }
            if (field.inputType === 'checkbox') {
              return (
                <Col xs="6" key={index}>
                  <FormGroup className="mr-3">
                    <label className="form-control-label">{field.label}</label>
                    <Input
                      className="form-control-alternative new-event--title ml-4"
                      type={field.inputType}
                      name={field.name}
                      placeholder={field.placeholder}
                      onChange={handleInputs}
                      checked={modalState.cartData?.[field.name] || false}
                    />
                  </FormGroup>
                </Col>
              );
            }

            if (field.inputType === 'number') {
              return (
                <Col xs="6" key={index}>
                  <FormGroup className="mr-3">
                    <label className="form-control-label">
                      {typeof field.label === 'function' ? field.label(null, true) : field.label}
                    </label>
                    <Input
                      className="form-control-alternative new-event--title"
                      type={field.inputType}
                      name={field.name}
                      placeholder={field.placeholder}
                      onChange={handleInputs}
                      value={modalState.cartData?.[field.name] || ''}
                      min={field.min}
                    />
                  </FormGroup>
                </Col>
              );
            }
            
            if (field.inputType === 'textarea') {
              return (
                <Col xs="12" key={index}>
                  <FormGroup className="mr-3">
                    <label className="form-control-label">
                      {typeof field.label === 'function' ? field.label(null, true) : field.label}
                    </label>
                    <Input
                      className="form-control-alternative new-event--title w-100 p-3"
                      name={field.name}
                      placeholder={field.placeholder}
                      onChange={handleInputs}
                      value={modalState.cartData?.[field.name] || ''}
                      rows={6}
                      type="textarea"
                    />
                  </FormGroup>
                </Col>
              );
            }
            
            if (field.isStringOnly) {
              return (
                <Col xs="6" key={index}>
                  <FormGroup className="mr-3">
                    <label className="form-control-label">
                      {typeof field.label === 'function' ? field.label(null, true) : field.label}
                    </label>
                    <Input
                      className="form-control-alternative new-event--title"
                      type={field.inputType}
                      name={field.name}
                      placeholder={field.placeholder}
                      onChange={(e) => {
                        const value = e.target.value;
                        const hasNumber = /\d/.test(value);
                        if (hasNumber) {
                          setIsInvalid(true);
                        } else {
                          setIsInvalid(false);
                        }
                    
                        if (!hasNumber) {
                          return handleInputs(e);
                        }
                      }}
                      value={modalState.cartData?.[field.name] || ''}
                    />
                    {isInvalid && <p className="number-validation" style={{ marginTop: '1rem' }}>Numbers is not allowed</p>}
                  </FormGroup>
                </Col>
              );
            }

            return (
              <Col xs="6" key={index}>
                <FormGroup className="mr-3">
                  <div className="d-flex justify-content-between">
                    <label className="form-control-label">
                      {typeof field.label === 'function' ? field.label(null, true) : field.label}
                    </label>
                    {modalState.cartData?.[field.isEventDataWasModified] ? (
                      <button
                        className="text-primary revert-btn"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          hanldeRevertCartToOriginal(
                            modalState.packageOrderId,
                            modalState.cartId,
                            field.keyForReveringValue,
                          );
                        }}
                      >
                        Revert to original
                      </button>
                    ) : null}
                  </div>
                  <Input
                    className="form-control-alternative new-event--title"
                    type={field.inputType}
                    name={field.name}
                    placeholder={field.placeholder}
                    onChange={handleInputs}
                    value={modalState.cartData?.[field.name] || ''}
                    disabled={field.isDisabled}
                  />
                  {field.isShowInputNote ? <FormText>{field.inputNote}</FormText> : null}
                </FormGroup>
              </Col>
            );
          })}
        </div>
      );
  }
};

export default FormSection;