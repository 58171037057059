import { useApolloClient, useLazyQuery } from '@apollo/client';
import React from 'react';
import { Col, Container, Row, Spinner } from 'reactstrap';
import CreateNewCardModal from '../../components/Modals/CreateNewCardModal';
import EditPackageDetailsModal from '../../components/Modals/EditCardModal/EditPackageDetailsModal';
import DescktopUploadTicketsModal from '../../components/Modals/uploadTicketsModal/desktop/uploadTicketsModal';
import {
  GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
  GET_CARD_DESCRIPTIONS,
  GET_PACKAGE_WITH_DETAILS_BY_INVOICE_ID,
  GET_TICKET_TYPES,
} from '../../queries/vip-workflow';
import { IPackageOrderWithDetails } from '../../utils/interfaces';
import CartsContainer from './CartsContainer';
import './packageInfo.scss';
import PackageMenu from './PackageMenu';
import PublishAll from './PublishAll';
import UploadTickets from './UploadTickets';
import moment from 'moment';
import { useMediaQuery } from '../../utils/services';
import MobileUploadTicketsModal from '../../components/Modals/uploadTicketsModal/mobile/UploadTicketsModal';
import { CARD_FORM_SCHEMA } from '../../utils/cardConfigs';
import { GET_PROFILE } from '../../queries/auth';

const PackageInfoPage = ({
  match: {
    params: { id },
  },
}: any) => {
  const client = useApolloClient();
  const isDesktop = useMediaQuery('(min-width: 992px)');

  const [airportTransferType, setAirportTransferType] = React.useState<'transfer1' | 'transfer2'>(
    'transfer1',
  );
  const [vegasLimoTransferType, setVegasLimoTransferType] = React.useState<'transfer1' | 'transfer2'>(
    'transfer1',
  );
  const [packageDetailsId, setPackageDetailsId] = React.useState<number | null>(null);
  const [isEditPackageDetailsModalOpened, setIsEditPackageDetailsModalOpened] =
    React.useState(false);
  const [modalGroup, setModalGroup] = React.useState('');
  const [activeCardsFilter, setActiveCardsFilter] = React.useState('');
  const [packageOrderWithDetails, setPackageOrderWithDetails] =
    React.useState<IPackageOrderWithDetails | null>(null);
  const [isCartInfoOpenedId, setIsCartInfoOpenedId] = React.useState<number[]>([]);
  const [isLearnMoreOpenedId, setIsLearnMoreOpenedId] = React.useState<number[]>([]);
  const [availableFilters, setAvailableFilters] = React.useState([]);
  const [isUploadTicketsModalOpened, setIsUploadTicketsModalOpened] = React.useState(false);
  const [modaName, setModalName] = React.useState('');
  const [isCreateNewCardModalOpened, setIsCreateNewCardModalOpened] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [ticketTypes, setTicketTypes] = React.useState<any[]>([]);
  const [refreshNeeded, setRefreshNeeded] = React.useState(false);
  const [cardDescriptions, setCardDescriptions] = React.useState<any[]>([]);
  
  const triggerRefresh = () => {
    setRefreshNeeded(prev => !prev);
  };
  
  
  const [getUserProfile, getUserProfileResponse] = useLazyQuery(GET_PROFILE, {
    fetchPolicy: 'network-only',
  });

  const user = getUserProfileResponse?.data?.getProfile?.username
    ? getUserProfileResponse?.data?.getProfile?.username
    : getUserProfileResponse?.data?.getProfile?.email;

  const getPackageData = async () => {
    setLoading(true);

    const packageData = await client.query({
      query: GET_PACKAGE_WITH_DETAILS_BY_INVOICE_ID,
      variables: { invoiceId: Number.parseFloat(id), orderDetailsFilter: activeCardsFilter },
      fetchPolicy: 'network-only',
    });

    const filtersData = await client.query({
      query: GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
      variables: { invoiceId: Number.parseFloat(id) },
      fetchPolicy: 'cache-first',
    });
    
    const ticketTypeData = await client.query({
      query: GET_TICKET_TYPES,
      fetchPolicy: 'cache-first',
    });
    
    const cardDescriptionData = await client.query({
      query: GET_CARD_DESCRIPTIONS,
      fetchPolicy: 'cache-first',
    });
     
    Object.entries(CARD_FORM_SCHEMA).forEach(([key, value], id) => {
      if (key === 'Ticket' || key === 'Tailgate') {
        const options = ticketTypeData.data.getTicketTypes.filter((item: { groupType: string; }) => item.groupType === key).map((el: { id: any; deliveryType: any }) => {
          return {
            id: Number(el.id),
            name: el.deliveryType,
          };
        });
  
        value?.forEach((field: any) => {
          if (field.inputType === 'select') {
            field.options = [{id: null, deliveryType: ''}, ...options];
          }
        });
      }
    });
    
    setCardDescriptions(cardDescriptionData.data.getCardDescriptions);
    setTicketTypes(ticketTypeData.data.getTicketTypes);
    setPackageOrderWithDetails(packageData.data?.getOnePackageWithDetails);
    setAvailableFilters(filtersData.data?.getAvailableFiltersForPackageInfo.filtersList);
    setLoading(false);
  };

  const handleOpenDetails = (e: any, packageDetailsId: number) => {
    e.preventDefault();
    if (isCartInfoOpenedId.includes(packageDetailsId)) {
      setIsCartInfoOpenedId((prev) => {
        return prev.filter((el) => el !== packageDetailsId);
      });
    } else {
      setIsCartInfoOpenedId((prev) => {
        return [...prev, packageDetailsId];
      });
    }
  };

  const handleOpenLearnMore = (e: any, packageDetailsId: number) => {
    e.preventDefault();
    if (isLearnMoreOpenedId.includes(packageDetailsId)) {
      setIsLearnMoreOpenedId((prev) => {
        return prev.filter((el) => el !== packageDetailsId);
      });
    } else {
      setIsLearnMoreOpenedId((prev) => {
        return [...prev, packageDetailsId];
      });
    }
  };
  
  React.useEffect(() => {
    getPackageData();
    getUserProfile();
  }, [activeCardsFilter, refreshNeeded]);

  return (
    <>
      {isDesktop ? (
        <DescktopUploadTicketsModal
          isUploadTicketsModalOpened={isUploadTicketsModalOpened}
          setIsUploadTicketsModalOpened={setIsUploadTicketsModalOpened}
          packageDetails={packageOrderWithDetails?.orderDetails}
          invoiceId={Number.parseFloat(id)}
          isInvoiceArchied={
            moment(packageOrderWithDetails?.eventDate, 'MMMM Do YYYY, h:mm a') < moment()
          }
        />
      ) : (
        <MobileUploadTicketsModal
          isUploadTicketsModalOpened={isUploadTicketsModalOpened}
          setIsUploadTicketsModalOpened={setIsUploadTicketsModalOpened}
          packageDetails={packageOrderWithDetails?.orderDetails}
          invoiceId={Number.parseFloat(id)}
          isInvoiceArchied={
            moment(packageOrderWithDetails?.eventDate, 'MMMM Do YYYY, h:mm a') < moment()
          }
        />
      )}
      <EditPackageDetailsModal
        isModalOpened={isEditPackageDetailsModalOpened}
        setIsModalOpened={setIsEditPackageDetailsModalOpened}
        modalGroup={modalGroup}
        setModalGroup={setModalGroup}
        packageDetailsId={packageDetailsId}
        setPackageDetailsId={setPackageDetailsId}
        setPackageOrderWithDetails={setPackageOrderWithDetails}
        packageOrderWithDetails={packageOrderWithDetails}
        modaName={modaName}
        setModalName={setModalName}
        invoiceId={id}
        activeCardsFilter={activeCardsFilter}
        defaultAirportTransferType={airportTransferType}
        defaultVegasLimoTransferType={vegasLimoTransferType}
        ticketTypes={ticketTypes}
        cardDescriptions={cardDescriptions}
      />
      <CreateNewCardModal
        isModalOpened={isCreateNewCardModalOpened}
        setIsModalOpened={setIsCreateNewCardModalOpened}
        currentInvoiceId={Number.parseFloat(id)}
        setPackageOrderWithDetails={setPackageOrderWithDetails}
      />
      <Container className="mt-4" fluid>
        <Row>
          <Col sm="4" style={{ overflow: 'auto', height: '90vh', padding: '0' }}>
            <PackageMenu
              packageOrderWithDetails={packageOrderWithDetails}
              activeCardsFilter={activeCardsFilter}
              setActiveCardsFilter={setActiveCardsFilter}
              availableFilters={availableFilters}
            />
            <UploadTickets setIsUploadTicketsModalOpened={setIsUploadTicketsModalOpened} />
            <PublishAll
              invoiceId={Number.parseFloat(id)}
              orderDetails={packageOrderWithDetails?.orderDetails}
              setPackageOrderWithDetails={setPackageOrderWithDetails}
              setIsCreateNewCardModalOpened={setIsCreateNewCardModalOpened}
              resetCardsFilter={setActiveCardsFilter}
            />
          </Col>
          <Col sm="8" style={{ overflow: 'auto', height: '90vh' }}>
            {loading ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" variant="info" size="lg" />
              </div>
            ) : packageOrderWithDetails ? (
              <CartsContainer
                packageOrderWithDetails={packageOrderWithDetails}
                setPackageOrderWithDetails={setPackageOrderWithDetails}
                setModalGroup={setModalGroup}
                setModalName={setModalName}
                setPackageDetailsId={setPackageDetailsId}
                setIsModalOpened={setIsEditPackageDetailsModalOpened}
                airportTransferType={airportTransferType}
                setAirportTransferType={setAirportTransferType}
                vegasLimoTransferType={vegasLimoTransferType}
                setVegasLimoTransferType={setVegasLimoTransferType}
                isCartInfoOpenedId={isCartInfoOpenedId}
                handleOpenDetails={handleOpenDetails}
                handleOpenLearnMore={handleOpenLearnMore}
                isLearnMoreOpenedId={isLearnMoreOpenedId}
                user={user}
                triggerRefresh={triggerRefresh}
              />
            ) : (
              <h1>Items Not Found</h1>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

// const styles = StyleSheet.create({
//   body: {
//     paddingTop: 25,
//     paddingBottom: 65,
//     paddingHorizontal: 35,
//   },
//   title: {
//     fontSize: 20,
//     width: '70%',
//     textAlign: 'left',
//   },
//   header: {
//     width: '100%',
//   },
//   invoiceId: {
//     marginTop: 10,
//     fontSize: 20,
//     width: '50%',
//     textAlign: 'left',
//   },
//   details: {
//     marginTop: 30,
//   },
//   cart: {
//     marginTop: 15,
//     backgroundColor: '#f0f0f0',
//     padding: 10,
//   },
//   cartInfo: {
//     marginTop: 15,
//     flexDirection: 'row',
//     flexWrap: 'wrap',
//     width: '100%',
//   },
//   detailsInfo: {
//     width: '50%',
//     marginVertical: 5,
//   },
//   line: {
//     borderTop: '1px solid black',
//     height: '1px',
//     marginVertical: 10,
//   },
//   departure: {
//     marginTop: 15,
//   },
// });

// const MyDocument = ({ title, invoiceId, orderDetails }: any) => (
//   <Document>
//     <Page style={styles.body}>
//       <View style={styles.header}>
//         <View style={styles.title}>
//           <Text>{title}</Text>
//         </View>
//         <View style={styles.invoiceId}>
//           <Text>Invoice Id: {invoiceId}</Text>
//         </View>
//       </View>
//       <View style={styles.details}>
//         <View>
//           <Text style={styles.title}>Details:</Text>
//         </View>
//         <View>
//           {orderDetails.map((cart: any, id: number) => {
//             return (
//               <View style={styles.cart} key={id}>
//                 <Text style={styles.title}>{cart.type}</Text>
//                 <View style={styles.cartInfo}>
//                   {Object.entries(CART_DETAILS_HEADER_CONFIG).map(([key, value]) => {
//                     if (cart.type === 'Management Fee' && key === 'csr') return;

//                     return (
//                       <Text style={styles.detailsInfo}>
//                         {value.colTitle}:&nbsp;
//                         {renderFieldValue(
//                           key as keyof typeof CART_DETAILS_HEADER_CONFIG,
//                           cart,
//                           value,
//                         )}
//                       </Text>
//                     );
//                   })}
//                 </View>
//                 {cart.cartDetails ? (
//                   <View>
//                     <View style={styles.line}></View>
//                     {Object.entries(CARD_FORM_SCHEMA).map(([key, value]: any) => {
//                       if (key === cart.type) {
//                         if (key === 'Airport Transfer') {
//                           return (
//                             <View>
//                               <View>
//                                 <Text>Arrival:</Text>
//                                 {value.arrival.map((field: any) => {
//                                   if (field.isOneLineInputs) {
//                                     return field.data.map((el: any) => (
//                                       <Text style={styles.detailsInfo}>
//                                         {el.label}:&nbsp; {cart.cartDetails.arrival[el.name]}
//                                       </Text>
//                                     ));
//                                   }
//                                   return (
//                                     <Text style={styles.detailsInfo}>
//                                       {field.label}:&nbsp; {cart.cartDetails.arrival[field.name]}
//                                     </Text>
//                                   );
//                                 })}
//                               </View>
//                               <View style={styles.departure}>
//                                 <Text>Departure:</Text>
//                                 {value.departure.map((field: any) => {
//                                   if (field.isOneLineInputs) {
//                                     return field.data.map((el: any) => (
//                                       <Text style={styles.detailsInfo}>
//                                         {el.label}:&nbsp; {cart.cartDetails.arrival[el.name]}
//                                       </Text>
//                                     ));
//                                   }

//                                   return (
//                                     <Text style={styles.detailsInfo}>
//                                       {field.label}:&nbsp; {cart.cartDetails.arrival[field.name]}
//                                     </Text>
//                                   );
//                                 })}
//                               </View>
//                             </View>
//                           );
//                         }

//                         return value.map((field: any) => {
//                           return (
//                             <Text style={styles.detailsInfo}>
//                               {field.label}:&nbsp; {cart.cartDetails[field.name]}
//                             </Text>
//                           );
//                         });
//                       }
//                     })}
//                   </View>
//                 ) : null}
//               </View>
//             );
//           })}
//         </View>
//       </View>
//     </Page>
//   </Document>
// );

export default PackageInfoPage;
